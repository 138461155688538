import React, { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useForm } from 'react-hook-form';
import { notify } from '../../../lib/common/notify';
import * as gtag from '../../../lib/events/gtag';
import { checkLead, formSubmitHandler } from './common/formSubmitHandler';
import CertifyingAuthority from './common/Inputs/CertifyingAuthority';
import { emailPattern } from './InputPatterns';
import SubscribeEmail from './common/Inputs/SubscribeEmail';
import { tracker } from '../../../lib/events/tracker';
import storeDataToLocal from '../forms/common/StoreDataToLocalStorage';
import { trackGa4Events } from '../../../lib/events/ga4/tracker';

const SubscriptionInput = tw.input`flex-1 p-[10px] text-white placeholder:text-white text-base outline-none bg-[#191919] border border-[#303030] min-w-0 h-[46px]`;

const SubscriptionButton = styled.button(({ disabled }) => [
  tw`bg-[#26d400] px-3 py-4 uppercase font-semibold text-sm rounded-r-md leading-4 h-[46px] cursor-pointer`,

  disabled && tw`cursor-not-allowed`,
]);

// TODO
export const NewsLetterSubscription = () => {
  const [pageURL, setPageURL] = useState('');
  const [buttonStatus, setButtonStatus] = useState<boolean>(false);

  useEffect(() => {
    setPageURL(window.location.href);
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onTouched',
  });
  const inputProps = {
    register,
    errors,
    setValue,
  };

  // submitHandler
  const submitHandler = async (data: any) => {
    // Certifying_authority check
    if (data.certifying_authority.length || buttonStatus) {
      return;
    }

    // Storing email in localStorage
    storeDataToLocal(data);

    setButtonStatus(true);

    const leadType = 'newsLetterSubscription';
    const extraData = {
      static_lead_type: 'NEWS_LETTER_SUBSCRIPTION',
      page: `${pageURL}`,
    };
    const count = await checkLead(extraData?.static_lead_type, data?.email);
    if (count) {
      reset();
      notify('error', 'Already Subscribed to Newsletter');
      setButtonStatus(false);
      return;
    }
    const leadData = { ...data, ...extraData };
    const response = await formSubmitHandler(leadData, leadType);

    // lead trigger
    gtag.event({
      action: 'Submit',
      name: 'Subscription Form Submit',
      label: 'Subscription Form Submit',
    });

    tracker({
      eventAction: 'Submit',
      eventLabel: 'Subscription Form Submit',
      eventName: 'submit_subscription_footer',
      trackerType: 'moengage',
      data: {
        url: `${pageURL}`,
      },
    });

    // Ga4 Event trigger
    trackGa4Events('submit-weekly-newsletter-bottom-nav', leadData);
    
    if (response.result.status) {
      reset();
      notify('success', `Subscribed to Newsletter Successfully`);
      setButtonStatus(false);
      return;
    }
    notify('error', response.result.message);
    setButtonStatus(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        {/* Certifying_authority */}
        <CertifyingAuthority {...inputProps} />
        <div tw="flex">
          <SubscribeEmail {...inputProps} />
          <SubscriptionButton disabled={buttonStatus} type="submit">
            Subscribe
          </SubscriptionButton>
        </div>
      </form>
      <p className={`${errors?.email?.type ? 'visible' : 'invisible'} text-red-600 text-xs mt-1 mx-1`}>
        {errors?.email?.type === 'required'
          ? 'This field is required'
          : errors?.email?.type === 'pattern'
          ? 'Enter a valid Email address.'
          : 'Something went wrong'}
      </p>
    </>
  );
};
