const storeDataToLocal = (data: any) => {
  delete data.certifying_authority;
  delete data.message;
  delete data.europe_gdpr_consent;
  delete data.whatsapp_subscription_status;

  const prevStoredData = {
    ...JSON.parse(localStorage.getItem('formData')),
    ...data,
  };
  localStorage.setItem('formData', JSON.stringify(prevStoredData));
};

export default storeDataToLocal;
