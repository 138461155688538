import { BaseSyntheticEvent } from 'react';

const stringWithSpace = {
  required: true,
  pattern: /^[A-Za-z, \s]+$/i,
  maxLength: 30,
  minLength: 2,
};

const stringWithSingleSpace = {
  required: true,
  pattern: /^[a-zA-Z ]*$/i,
  maxLength: 30,
  minLength: 2,
  onChange: (event: BaseSyntheticEvent) => (event.target.value = event.target.value.replace(/\s\s+/g, ' ')),
};

const singleStringWithSingleSpace = {
  required: true,
  pattern: /^[A-Za-z]+$/i,
  maxLength: 20,
  minLength: 1,
};

const phoneNumber = {
  required: true,
  pattern: /^[\d]+$/i,
  minLength: 5,
  maxLength: 10,
  onChange: (event: BaseSyntheticEvent) => (event.target.value = event.target.value.replace(/[^0-9]/g, '')),
};

const alphanumericStringWithSpace = {
  required: true,
  pattern: /^[\w, \s]+$/i,
};

export const emailPattern = {
  required: true,
  pattern:
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
};

export const participantsPattern = {
  required: true,
  pattern: /^[\d]+$/i,
  minLength: 1,
};

export const NRICPattern = {
  required: true,
  pattern: /^[\d]+$/i,
  minLength: 4,
  maxLength: 4,
  onChange: (event: BaseSyntheticEvent) => (event.target.value = event.target.value.replace(/[^0-9]/g, '')),
};

export const mandatoryCheckBox = {
  required: true,
};

export const YearOfExperiencePattern = {
  required: true,
};

export const fullNamePattern = stringWithSpace;

export const namePattenWithSingleString = singleStringWithSingleSpace;

export const namePattern = stringWithSingleSpace;

export const phoneNoPattern = phoneNumber;

export const companyNamePattern = alphanumericStringWithSpace;
