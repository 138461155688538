import tw from 'twin.macro';
import { FC, useEffect, useState } from 'react';
import { InputProps } from './interface';
import { emailPattern } from '../../InputPatterns';

const SubscriptionInput = tw.input`flex-1 p-[10px] text-white placeholder:text-white text-base outline-none bg-[#191919] border border-[#303030] min-w-0 h-[46px] rounded-l-md`;

const SubscribeEmail: FC<InputProps> = ({ register, setValue }) => {
  let storedValues;
  const [email, setEmail] = useState('');

  useEffect(() => {
    storedValues = localStorage.getItem('formData');
    setEmail(JSON.parse(storedValues)?.email || '');
    email?.length && setValue('email', email, { shouldValidate: true });
  }, [email]);

  return (
    <SubscriptionInput defaultValue={email} {...register('email', emailPattern)} placeholder="Enter Your E-mail" />
  );
};

export default SubscribeEmail;
